import { initServerFetchNumValidation } from './initServerFetchNumValidation';
import { initAnimationOnSearchPhone } from './initAnimationOnSearchPhone';

export const initPhoneMaskAndValidatePhone = () => {
    // VARS
    const locationForms = document.querySelectorAll('[data-phone-number-form]');
    if (!locationForms.length) return;
    const hiddenTelInput = document.querySelector('[data-phone-number-input-value]');
    const popupPhoneNumber = document.querySelector('[data-popup-phone-number]');
    const locationContainer = document.querySelector('[data-location]');

    // LISTENERS
    locationForms.forEach((form, index) => {
        form.addEventListener('submit', function (event) {
            event.preventDefault();
            const input = this.querySelector('[data-tell-input]');
            const phoneNumber = window[`phoneMask_${index}`].getNumber();
            const replacedNumber = window[`phoneMask_${index}`].getNumber().replace(/[^0-9]/g, '');
            const isNumberHasZero = replacedNumber.match(/0{7,}/);

            if (isNumberHasZero) {
                form.classList.add('error');
                return;
            }

            hiddenTelInput.value = replacedNumber;
            locationContainer.dataset.location = window[`phoneMask_${index}`].defaultCountry;

            if (window[`phoneMask_${index}`].defaultCountry === 'de') {
                if (
                    input.value.trim().length === input.placeholder.length ||
                    input.value.trim().length === input.placeholder.length - 1
                ) {
                    startAnimationButton(this);
                    startAnimationMap(form, phoneNumber, replacedNumber, window[`phoneMask_${index}`]);
                } else {
                    form.classList.add('error');
                }
            } else if (window[`phoneMask_${index}`].defaultCountry === 'my') {
                if (
                    input.value.trim().length === input.placeholder.length ||
                    input.value.trim().length === input.placeholder.length - 1 ||
                    input.value.trim().length === input.placeholder.length - 2
                ) {
                    startAnimationButton(this);
                    startAnimationMap(form, phoneNumber, replacedNumber, window[`phoneMask_${index}`]);
                } else {
                    form.classList.add('error');
                }
            } else {
                if (input.value.trim().length === input.placeholder.length) {
                    startAnimationButton(this);
                    startAnimationMap(form, phoneNumber, replacedNumber, window[`phoneMask_${index}`]);
                } else {
                    form.classList.add('error');
                }
            }
        });
    });

    // FUNCTIONS
    function startAnimationMap(form, phoneNumber, replacedNumber, iti) {
        form.classList.remove('error');
        popupPhoneNumber.innerText = phoneNumber.trim();
        locationContainer.dataset.location = iti.defaultCountry;
        initAnimationOnSearchPhone();
        disableScroll();
        initServerFetchNumValidation(replacedNumber);
    }

    function startAnimationButton(form) {
        const button = form.querySelector('[data-phone-number-form-submit]');
        button.classList.add('active-spinner');
        form.setAttribute('disabled', '');
    }
};
