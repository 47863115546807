import { redirect } from '../../../../../../Common/Resources/src_front/js/components/initRedirectToCheckout';

export function initServerFetchNumValidation(phoneNumber) {
    // VARS
    const form = document.querySelector('[data-form-popup]');
    const popup = document.querySelector('[data-popup-location]');
    if (!popup && !form) return;
    const popupSuccessBlock = popup.querySelector('[data-popup-success-block]');
    const buttonsLoaders = document.querySelectorAll('[data-button-loader]');
    // const validationUrl = popup.getAttribute('data-url');
    // const csrfToken = popup.getAttribute('data-csrf-token');
    // const data = { number: phoneNumber, token: csrfToken };

    // INITS
    [...buttonsLoaders].forEach((buttonLoader) => {
        buttonLoader?.classList.add('is-visible');
    });

    popup.classList.remove('hidden');
    setTimeout(() => {
        showSuccessPopupAndSubmit(1500);
    }, 3300);

    // fetch(validationUrl, {
    //     method: 'POST',
    //     body: JSON.stringify(data),
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    // })
    //     .then((response) => {
    //         return response.json();
    //     })
    //     .then(() => {
    //         popup.classList.remove('hidden');
    //         setTimeout(() => {
    //             showSuccessPopupAndSubmit(1500);
    //         }, 3300);
    //     })
    //     .catch(function () {
    //         popup.classList.remove('hidden');
    //         showSuccessPopupAndSubmit(3300);
    //     });

    // FUNCTIONS
    function showSuccessPopupAndSubmit(timerSubmit) {
        setTimeout(() => {
            popupSuccessBlock.classList.add('success');
            popup.classList.add('success');
            document.querySelector('[data-popup-points]').classList.add('hidden');

            if (form) {
                setTimeout(() => {
                    if (document.querySelector('[data-plan-form]').dataset.planForm === 'submit') {
                        form.submit();
                    } else {
                        redirect(form);
                    }
                }, 500);
            }
        }, timerSubmit);
    }
}
