export function generateGA() {
    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    if (!getCookie('_ga')) {
        return {
            ga_client_id: null,
            ga_session_id: null,
        };
    }

    const gaClientId = document.cookie
        .match(/_ga=(.+?);/)[1]
        .split('.')
        .slice(-2)
        .join('.');

    let gaSessionId = null;
    if (getCookie('_ga_KZK9D3B5G0')) {
        gaSessionId = getCookie('_ga_KZK9D3B5G0').split('.', 3)[2];
    }

    return {
        ga_client_id: gaClientId,
        ga_session_id: gaSessionId,
    };
}
