import { isValidMail } from './helper-function/isValidMail';
import { redirect } from '../../../../../../Common/Resources/src_front/js/components/initRedirectToCheckout';

export const initCreateAccountEmail = () => {
    // VARS
    const form = document.querySelector('[data-sign-form]');
    if (!form) return;
    const inputEmail = form.querySelector('[data-validate-field-up]');
    const errorInputEmail = form.querySelector('[data-input-error]');
    const agreeCheckbox = form.querySelector('[data-validate-checkbox]');
    const errorAgreeCheckbox = form.querySelector('[data-checkbox-error]');
    let isValid = true;

    // LISTENERS
    form.addEventListener('submit', handleOnSubmit, false);

    inputEmail.addEventListener('focus', handleOnFocusEmailInput, false);

    agreeCheckbox.addEventListener('change', handleOnChangeAgreeCheckbox, false);

    // HANDLERS
    function handleOnSubmit(event) {
        validateForm(event, this);
    }

    function handleOnChangeAgreeCheckbox() {
        if (this.checked) {
            hideErrorMessageAgreeCheckbox();
        }
    }

    function handleOnFocusEmailInput() {
        hideErrorMessageEmail();
    }

    // FUNCTIONS
    function validateForm(event, form) {
        event.preventDefault();
        validateEmail();
        validateAgreeCheckbox();

        if (!isValid) {
            isValid = true;
        } else {
            redirect(form);
        }
    }

    function validateEmail() {
        if (isValidMail(inputEmail.value.trim())) {
            hideErrorMessageEmail();
        } else {
            isValid = false;
            showErrorMessageEmail();
        }
    }

    function validateAgreeCheckbox() {
        if (agreeCheckbox.checked) {
            hideErrorMessageAgreeCheckbox();
        } else {
            isValid = false;
            showErrorMessageAgreeCheckbox();
        }
    }

    function hideErrorMessageEmail() {
        errorInputEmail.classList.add('hidden');
        inputEmail.classList.remove('input-error');
    }

    function showErrorMessageEmail() {
        errorInputEmail.classList.remove('hidden');
        inputEmail.classList.add('input-error');
    }

    function showErrorMessageAgreeCheckbox() {
        errorAgreeCheckbox.classList.remove('hidden');
    }

    function hideErrorMessageAgreeCheckbox() {
        errorAgreeCheckbox.classList.add('hidden');
    }
};
