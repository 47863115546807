import { initHamburger } from './components/_initHumburger';
import { initDropdown } from './components/_initDropdown';
import { initMobileMenu } from './components/_initMobileMenu';
import { initTabs } from './components/_initTabs';
import { initScrollAnimation } from './components/_initScrollAnimation';
import { initScrollToAnchor } from './components/_initScrollToAnchor';
import { initLazyLoadFonts } from './components/_initLazyLoadFonts';
import { initScrollLocker } from './components/_initScrollLocker';
import { initForms } from './components/_initForms';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initHashEmail } from '../../../../Common/Resources/src_front/js/components/initHashEmail';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initPhoneMaskAndValidatePhone } from './components/phone-number-form/initPhoneMaskAndValidatePhone';
import { initFetchCheckoutAssetsAndCaching } from '../../../../Common/Resources/src_front/js/components/initFetchCheckoutAssetsAndCaching';

document.addEventListener('DOMContentLoaded', handleOnLoadPage, false);

window.addEventListener('load', function () {
    initFetchCheckoutAssetsAndCaching();
});

// If the browser has cached the page
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        window.location.reload();
    }
});

function handleOnLoadPage() {
    initLazyLoadFonts();
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initPhoneMaskAndValidatePhone();
    initHashEmail();
    initScrollLocker();
    initMobileMenu();
    initHamburger();
    initDropdown();
    initTabs();
    initScrollAnimation();
    initScrollToAnchor();
    initForms();
}
