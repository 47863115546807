export const initAppendHiddenInputsToPlanForms = () => {
    // VARS
    const priceForms = document.querySelectorAll('[data-price-form]');
    const dataUrl = sessionStorage.getItem('url');
    const items = document.querySelectorAll('[data-accordion-input]');
    const itemCodeInput = document.getElementById('item_code');

    items.forEach((item) => {
        item.addEventListener(
            'click',
            () => {
                const input = item.querySelector('input[type="radio"]');
                if (input && itemCodeInput) {
                    itemCodeInput.value = input.value;
                }
            },
            true,
        );
    });

    // INITS
    if (dataUrl) {
        appendHiddenInputsToForms();
    }

    // FUNCTIONS
    function appendHiddenInputsToForms() {
        const searchParams = new URLSearchParams(dataUrl);

        [...priceForms].forEach((form) => {
            for (const searchParam of searchParams) {
                form.append(createHiddenInput(searchParam));
            }
        });
    }
};

export function createHiddenInput([name, value]) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = value;

    return input;
}
