import lottie from 'lottie-web';

export const initAnimationOnSearchPhone = () => {
    // VARS
    const container = document.querySelector('[data-location]');
    const animationSrcElem = document.querySelector('[data-animation-location-src]');
    if (!container) return;
    const fetchUrl = `${window.location.origin}${animationSrcElem.dataset.animationLocationSrc}`;

    fetch(fetchUrl)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            initAnimation(data);
        });

    // FUNCTION
    function initAnimation(animation) {
        lottie.loadAnimation({
            container: container,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation,
        });
    }
};
